export default {
  baseUrl: process.env.VUE_APP_API_BASE_URL || 'https://app.owatts.io/api/v1/', // 'http://owatts.test/api/v1/',
  iotBaseUrl: process.env.VUE_APP_IOT_BASE_URL || 'https://iot2.owattspay.net/api/v2/',
  socketBaseUrl: process.env.VUE_APP_SOCKET_BASE_URL || 'ws://iotbridge.owattspay.net',
  socketToken: process.env.VUE_APP_SOCKET_TOKEN || 'Dxej1z3x.QTWfcxoITEF0u7A/IwkwHYBiqZkRdUbFsZ4uEGDttdFK',
  lifestyleUrl: process.env.VUE_APP_API_LIFESTYLE_URL || 'https://app.owatts.io/api/v1/', //'http://owatts.test/api/v1/',
  appUrl: process.env.VUE_APP_API_LIFESTYLE_URL || 'https://owatts.io/', //'http://owatts.test/api/v1/',
  Url: process.env.VUE_APP_API_LIFESTYLE_URL || 'https://app.owatts.io/', //'http://owatts.test/api/v1/',
  greenbankUrl: process.env.VUE_APP_GREENBANK_URL || 'https://ibank.greenbankcoin.com', //'http://owatts.test/api/v1/',
  uatBaseUrl: process.env.VUE_APP_IOT_BASE_URL || 'https://uat.owatts.io/api/v1/',
  greenbank_merchant_key: process.env.VUE_APP_GREENBANK_MERCHANT_KEY || '44caebfb-7a2c-4ac1-826b-ffecde4b07a3',
  greenbank_domain: process.env.VUE_APP_GREENBANK_DOMAIN || 'test',
}

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store/index'
// import axios from 'axios'
import moshaToast from 'mosha-vue-toastify'
import { createToast } from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'
import VueCarousel from 'vue-carousel'


import DocsCallout from '@/components/DocsCallout'

import io from 'socket.io-client'
import config from '@/config/'
import SmartTable from 'vuejs-smart-table'

// Components
import AppLoader from './components/AppLoader'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import DateRangePicker from './components/DateRangePicker.vue'
import Pagination from './components/Pagination.vue'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import HollowSpinner from '@/components/HollowSpinner'
import DataTable from '@/components/DataTable'
import SmartDataTable from '@/components/SmartDataTable'
import ContentPlaceHolder from '@/components/ContentPlaceHolder.vue'

dayjs.extend(duration)
dayjs.extend(relativeTime)
// import 'vue3-date-time-picker/dist/main.css'

// register jw pagination component globally

import mixin from './plugins/toast'

import VueClipboard from 'vue3-clipboard'

import 'flowbite'

// import { VueReCaptcha } from 'vue-recaptcha-v3'

const app = createApp(App)

app.use(store)
app.use(VueCarousel)
app.use(SmartTable)
app.use(VueClipboard)


// app.use(VueReCaptcha, { siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' })
// app.use(VueReCaptcha, { siteKey: '6Lf4A1MmAAAAAP8HEzgt5FcUPc0nt2xcNchfSx4J' })

// app.use(axios)
app.use(router)
// app.use(CoreuiVue)
// app.provide('icons', icons)
// app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)

app.component('date-range-picker', DateRangePicker)
app.component('Datepicker', Datepicker)
app.component('app-pagination', Pagination)
app.component('data-table', DataTable)
app.component('ContentPlaceHolder', ContentPlaceHolder)
app.component('SmartDataTable', SmartDataTable)

app.use(moshaToast)
// app.use(createToast)
app.component('AppLoader', AppLoader)
app.component('HollowSpinner', HollowSpinner)

var socketlink = 'ws://iotbridge.owattspay.net'
var socket = io.connect('ws://iotbridge.owattspay.net', { reconnect: false, preconnect: true })

app.config.globalProperties.$socket = socket
app.config.globalProperties.$socketlink = socketlink

app.config.globalProperties.dayjs = dayjs

app.config.globalProperties.$config = config

app.use(require('vue3-jalali-moment'))

app.mixin({
  methods: {
    makeToast(title, message, toaster) {
      if (title && message && toaster) {
        createToast(
          {
            title: title,
            description: message,
          },
          {
            type: toaster,
            timeout: 3000,
            hideProgressBar: 'true',
            showIcon: 'true',
          },
        )
      }
    },
    formatCurrency(amount, currency) {
      return amount.toLocaleString('en-US', {
        style: 'currency',
        currency: currency,
      })
    },
  },
})

app.mount('#app')
